export const QUESTION_TEXT_TYPE = 2;
export const QUESTION_IMAGE_TYPE = 1;

//VOTE VARIABLES
export const VOTE_TYPE_LIKE = 'like';
export const VOTE_TYPE_DISLIKE = 'dislike';
export const VOTE_POST_SOURCE = 'post';
export const VOTE_COMMENT_SOURCE = 'comment';
//END VOTE VARIABLES

//COMMENT VARIABLE
export const COMMENT_POST_SOURCE = 'post'
export const COMMENT_REQUEST_SOURCE = 'request'
//END COMMENT VARIABLE

export const TEST_EVALUATION_TYPE_POINTS = 1;

// UPLOAD SOURCE TYPE
export const UPLOAD_TYPE_TEST = 'test';
export const UPLOAD_TYPE_LIBRARY = 'library';
export const UPLOAD_TYPE_COURSE = 'course';
export const UPLOAD_TYPE_POST = 'post';
export const UPLOAD_TYPE_EVENT = 'event';
export const UPLOAD_TYPE_AVATAR = 'user_avatar';
export const UPLOAD_TYPE_WECHAT = 'user_weechat';
export const UPLOAD_TYPE_KNOWLEDGE_BASE_FILE = 'knowledge_base_document';
export const UPLOAD_TYPE_ABSENCE_FILE = 'absence_document';
export const UPLOAD_TYPE_CHARITY = 'charity';
export const UPLOAD_TYPE_CHARITY_REPORT = 'charity_report';
export const UPLOAD_TYPE_CELEBRATION = 'user_congratulation';

//END UPLOAD SOURCE TYPE

//ALERT
export const ALERT_SUCCESS = 'success';
export const ALERT_ERROR = 'error';
export const ALERT_WARNING = 'warning';
//END ALERT

//SEARCH ITEM TYPE
export const SEARCH_ITEM_TYPE_CATEGORY = 'category';
export const SEARCH_ITEM_TYPE_DOCUMENT = 'document';
export const SEARCH_ITEM_TYPE_TEST = 'test';
//END SEARCH ITEM TYPE

//COURSE DRAG AND DROP GROUPS
export const COURSE_DRAG_DROP_DOCUMENT = 'documents';
export const COURSE_DRAG_DROP_TEST = 'tests';
export const COURSE_DRAG_DROP_COURSE = 'course';
export const DRAG_DROP_GROUP_COURSE = 'course';
//END COURSE DRAG AND DROP GROUPS


//PUBLICATION VARIABLES
export const PUBLICATION_ITEM_COLOR_RED = 'red';
export const PUBLICATION_ITEM_COLOR_GREEN = 'green';
export const PUBLICATION_ITEM_COLOR_ORANGE = 'orange';
export const PUBLICATION_ITEM_COLOR_BLUE = 'blue';
export const PUBLICATION_ITEM_COLOR_BROWN = 'brown';
export const PUBLICATION_ITEM_COLOR_CRIMSON = 'crimson';

export const PUBLICATION_TYPE_ITEM_COMPANY = 'company';
export const PUBLICATION_TYPE_ITEM_DEPARTMENT = 'department';
export const PUBLICATION_TYPE_ITEM_USER = 'user';
export const PUBLICATION_TYPE_ITEM_EMAIL = 'email';
export const PUBLICATION_TYPE_ITEM_PARTNER = 'partner';
export const PUBLICATION_TYPE_ITEM_ALL = 'all';
//END PUBLICATION VARIABLES

//QUIZ QUESTION TYPES
export const QUIZ_ANSWER_ONE = 1;
export const QUIZ_ANSWER_MULTIPLE = 2;
export const QUIZ_ANSWER_RANGE = 3;
export const QUIZ_ANSWER_YOUR_ANSWER = 4;

export const QUIZ_STATUS_CLOSED = 'closed';
export const QUIZ_STATUS_ACTIVE = 'active';

export const LOCAL_STORAGE_BACK_QUIZ_PAGE = 'local_storage_back_test_page';
//END QUIZ QUESTION TYPES

//EVENT
export const ADD_EVENT_PARTICIPANT = 'add';
export const REMOVE_EVENT_PARTICIPANT = 'remove';
// END EVENT

// THANKS
export const THANKS_TYPE_COMPANY = 'company';
export const THANKS_TYPE_DEPARTMENT = 'department';
export const THANKS_TYPE_USER = 'user';
// END THANKS

// TABS
export const TAB_QUERY_PROFILE = 'profile';
export const TAB_QUERY_SKILLS = 'skills';
export const TAB_QUERY_INTERESTS = 'interests';
export const TAB_QUERY_EDUCATION = 'education';
export const TAB_QUERY_CELEBRATIONS = 'celebrations';
export const TAB_QUERY_ABSENCES = 'absences';
// END TABS

//ADMIN REQUEST FORM TYPE
export const ADMIN_REQUEST_FORM_TYPE_OTHER = 1;
export const ADMIN_REQUEST_FORM_TYPE_NEW_USER = 2;
export const ADMIN_REQUEST_FORM_TYPE_DEACTIVATE_USER = 3;
//END ADMIN REQUEST FORM TYPE

//ADMIN TYPE FIELDS IN REQUEST
export const ADMIN_REQUEST_TYPE_INPUT = 'input';
export const ADMIN_REQUEST_TYPE_TEXTAREA = 'textarea';
export const ADMIN_REQUEST_TYPE_SELECT = 'select';
//END ADMIN TYPE FIELDS IN REQUEST

// KNOWLEDGE BASE GUIDES TYPES
export const KNOWLEDGE_BASE_GUIDE_SECTION = 'knowledge_base_guide_section';
export const KNOWLEDGE_BASE_DOCUMENT = 'knowledge_base_document';
export const KNOWLEDGE_BASE_TYPE_DOCUMENT = 'document';
export const KNOWLEDGE_BASE_TYPE_GUIDE = 'guide';
// END KNOWLEDGE BASE GUIDES TYPES

//ADMIN INPUT TYPES IN REQUEST

export const ADMIN_REQUEST_FIELD_INPUT_TYPE_TEXT = 1;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_NUMBER = 2;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_DATE = 3;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_DATETIME = 4;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_RADIO = 5;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_FILE = 6;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_CHECKBOX = 7;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_PHONE = 8;
export const ADMIN_REQUEST_FIELD_INPUT_TYPE_EMAIL = 9;
//END ADMIN INPUT TYPES IN REQUEST

//ADMIN REQUEST INPUT TYPE FILES
export const ADMIN_REQUEST_INPUT_TYPE_FILE_SINGLE = 1;
export const ADMIN_REQUEST_INPUT_TYPE_FILE_MULTIPLE = 2;
//END ADMIN REQUEST INPUT TYPE FILES

//ADMIN REQUEST FILE FORMAT
export const ADMIN_REQUEST_FILE_FORMAT_IMAGE = 1;
export const ADMIN_REQUEST_FILE_FORMAT_FILE = 2;
//END ADMIN REQUEST FILE FORMAT

//REQUEST USER FORM STATUS
export const REQUEST_FORM_USER_STATUS_OPEN = 1;
export const REQUEST_FORM_USER_STATUS_IN_PROCESS = 2;
export const REQUEST_FORM_USER_STATUS_FINISHED = 3;
export const REQUEST_FORM_USER_STATUS_CANCEL = 4;
//END REQUEST USER FORM STATUS

//ABSENCES STATUS
export const ABSENCE_REQUEST_STATUS_OPEN = 1;
export const ABSENCE_REQUEST_STATUS_AGREED = 2;
export const ABSENCE_REQUEST_STATUS_REJECTED = 3;
export const ABSENCE_REQUEST_STATUS_CANCELED = 4;
// END ABSENCES STATUS

//ABSENCES TYPE
export const VACATION_ABSENCE = 1;
// END ABSENCES TYPE

//ADMIN REQUEST SELECT VARIANTS
export const ADMIN_REQUEST_SELECT_VARIANT_USER = 1;
export const ADMIN_REQUEST_SELECT_VARIANT_COMPANY = 2;
export const ADMIN_REQUEST_SELECT_VARIANT_DEPARTMENT = 3;
export const ADMIN_REQUEST_SELECT_VARIANT_POSITION = 5;
export const ADMIN_REQUEST_SELECT_VARIANT_OTHER = 4;
export const ADMIN_REQUEST_SELECT_VARIANT_CALENDAR = 6;
//END ADMIN REQUEST SELECT VARIANTS

//CELEBRATIONS TYPES VARIANTS
export const BIRTHDAY_CELEBRATION_NOTIFICATION_TYPE = "birthday_congratulation_created";
export const BIRTHDAY_CELEBRATION_TYPE_ID = 1;
export const VALENTINE_CELEBRATION_TYPE_ID = 2;
export const VALENTINE_CELEBRATION_DATE = '14-02';
export const VALENTINE_CELEBRATION_NOTIFICATION_TYPE = "valentines_congratulation_created";
export const MENS_DAY_CELEBRATION_TYPE_ID = 3;
export const MENS_DAY_CELEBRATION_DATE = '23-02';
export const MENS_DAY_CELEBRATION_NOTIFICATION_TYPE = "mans_day_congratulation_created";
export const WOMANS_DAY_CELEBRATION_TYPE_ID = 4;
export const WOMANS_DAY_CELEBRATION_PRE_DATE = '07-03';
export const WOMANS_DAY_CELEBRATION_DATE = '08-03';
export const WOMANS_DAY_CELEBRATION_NOTIFICATION_TYPE = "womans_day_congratulation_created";
//END TYPES VARIANTS

// EMPLOYEES TRANSFER TYPES
export const EMPLOYEE_ACCEPTED_TYPE = 2;
export const EMPLOYEE_REJECTED_TYPE = 3;
// END EMPLOYEES TRANSFER TYPES

// DEFAULT AVATAR PATH
export const DEFAULT_AVATAR_PATH = '/img/default_avatar.svg';
// END DEFAULT AVATAR PATH
