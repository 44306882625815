<script setup>
import {computed, onUpdated, reactive, watch} from "vue";
import {useAlertStore} from "~/store/useAlertStore";

const {clearInfo, } = useAlertStore();
const data = reactive({
    show: true,
});

const info = computed(() => {
    return (useAlertStore().getMessage?.message ?? []);
});

const alertType = computed(() => {
    return (useAlertStore().getMessage?.type ?? '');
});
watch(info, async (newValue, oldValue) => {
    if (newValue.length === oldValue.length) {
        return;
    }

    await setTimeout(() => {
        clearInfo();
    }, 3000);
});
</script>

<template>
    <div class="alert-custom" :class="[{'show-alert': (info.length > 0)}, `alert-type-${alertType}`]">
<!--        <div class="icon">O</div>-->
        <div class="message">
            <ul style="list-style: inside;">
                <li v-for="(message, index) in info" :key="index">{{message}}</li>
            </ul>
        </div>
        <div class="action hover" @click="clearInfo">&otimes;</div>
    </div>
</template>
