import { readonly, ref } from 'vue';

export function useGlobalModal() {

    let resolveCallback = () => {};

    const visible = ref(false);

    const ask = () => {
        visible.value = true;
        // after this point, calling resolveCallback will call resolve and return a result from `ask()`
        return new Promise((resolve) => {
            resolveCallback = resolve;
        });
    };

    const tell = (value) => {
        visible.value = false;
        resolveCallback(value);
    };

    return {
        visible: readonly(visible),
        ask,
        tell,
    };
}
