import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert-store', {
    state: () => {
        return {
            info: {
                type: '',
                message: []
            },
        }
    },

    getters:{
       getMessage: (state) => {
           if (Object.keys(state.info).length) {
               return state.info;
           }

           return null;
       },
    },

    actions:{
       setInfo(alert) {
          this.info = alert;
       },

       clearInfo() {
          this.info = {
              type: '',
              message: []
          };
       }
    },
});
